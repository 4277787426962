import {createSlice, PayloadAction, UnknownAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IEvent from "../../interfaces/IEvent.ts";
import ITalent from "../../interfaces/ITalent.ts";

type IStore = {
  booking?: IEvent
  type?: { name: string, color: string }
  jobType?: { name: string, color: string }
  completed?: string;
  editing: boolean;
}

const initialState: IStore = {
  editing: true,
};

const store = createSlice({
  name: EStores.BOOKING,
  initialState,
  reducers: {
    setBooking(state: IStore, data: PayloadAction<IEvent>) {
      state.booking = data.payload;

      if (state.booking) {
        const values: boolean[] = [
          state.booking.documents?.length !== 0,
          state.booking.talent?.length !== 0,
          !!state.booking.name,
          !!state.booking.type,
          !!state.booking.customer?.id,
          !!state.booking.customerContact?.name,
          !!state.booking.team?.agent,
          !!state.booking.team?.other,
          !!state.booking.team?.makeup,
          !!state.booking.team?.hair,
          !!state.booking.team?.stylist,
          !!state.booking.team?.director,
          !!state.booking.team?.division,
          !!state.booking.team?.photographer,
          !!state.booking.travel?.details,
          !!state.booking.travel?.hotel,
          !!state.booking.travel?.driver,
        ]

        const cmp = values.filter((value) => value).length

        state.completed = (cmp / values.length * 100).toFixed(1)
      }

      return
    },
    setBookingType(state: IStore, data: PayloadAction<{ name: string, color: string }>) {
      state.type = data.payload;
      return
    },
    setBookingJobType(state: IStore, data: PayloadAction<{ name: string, color: string }>) {
      state.jobType = data.payload;
      return
    },
    clearBooking(state: IStore) {
      state.booking = undefined;
      return
    },
    setBookingEditing(state: IStore, data: PayloadAction<boolean>) {
      state.editing = data.payload;
      return
    },
    setBookingTalents(state: IStore, data: PayloadAction<any[]>) {
      if(state.booking){
        state.booking.talent = data.payload;
      }
      return
    },
  },
});

export const {
  setBooking,
  setBookingType,
  setBookingJobType,
  clearBooking,
  setBookingEditing,
  setBookingTalents
} = store.actions;

export default store.reducer;
