import {useEffect, useState} from "react";
import ITalent from "../core/interfaces/ITalent";
import getProfilePicture from "../core/utils/getProfilePicture";
import updateCompanyPackageTalentStatusRequest from "../core/api/requests/companies/package/updateCompanyPackageTalentStatusRequest";
import { useDispatch } from "react-redux";
import convertToFraction from "../core/utils/convertToFraction";

export interface IComponent {
  talent: ITalent;
  companyId?:string
  packageId?:string
  showDetails?: boolean
  allowSelect?: boolean
  onSelect?: (talent:ITalent) => void
}

export default function TalentBigButton(props: IComponent) {
  const dispatch = useDispatch()
  const [ imageUri, setImageUri ] = useState<string>('')
  const [ statusSelection, setStatusSelection] = useState<string>('')

  useEffect(() => {
    const u = getProfilePicture(props.talent)
    setImageUri(u)
    setStatusSelection(props.talent.status || 'pending')
  }, [props.talent]);

  const redirectToTalent = () => {
    if (props.talent.id) {
      if(!props.showDetails){
        window.open(`/talents/${props.talent.id}`, '_blank')
      } else if(props.onSelect) {
        props?.onSelect(props.talent)
      }
    }
  }

  const changeStatus = async () => {
    if(props.companyId && props.packageId && props.talent.id){
      await updateCompanyPackageTalentStatusRequest(dispatch,props.companyId, props.packageId, props.talent.id, statusSelection);
    }
  }
  const [ TO, setTO ] = useState<any>(null)
  useEffect(() => {
    if (TO) {
      clearTimeout(TO)
    }

    setTO(setTimeout(() => {
      changeStatus()
    }, 500))
  }, [statusSelection]);

  const {measurements} = props.talent?.talent?.physical || {}


  return <div className={'talent-super-container col-6 col-md-4 col-lg-3 col-xl-2'}>
    <div className={`talent-container mb-1`}>
      <div className={'talent-background'} style={{backgroundImage: `url(${imageUri})`}} onClick={redirectToTalent}>
        {props.showDetails && 
          <div className={'talent-info position-absolute'}>
              <span className="talent-name text-capitalize fw-bold">{props.talent.name} {props.talent.lastName}</span>
              <span className="talent-height text-capitalize">{measurements?.height ? `${measurements.height}cm / ${convertToFraction(measurements.height)}` : ''}</span>
          </div>
        }
      </div>  
      {props.allowSelect &&
        <div className={'rc-talent-button-container text-center'}>
          <button onClick={() => statusSelection === 'like' ?  setStatusSelection('maybe') : setStatusSelection('like')} className={`${statusSelection} ${['like', 'maybe'].includes(statusSelection) ? 'selected' : ''}`}>
            <i className="fa-solid fa-check"></i>
          </button>
          <button onClick={() => setStatusSelection('unlike')} className={`unlike ${statusSelection === 'unlike' ? 'selected' : ''}`}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      }
    </div>
  </div>
}
