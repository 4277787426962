import clsx from "clsx";
import {useIntl} from "react-intl";
import {ulid} from "ulid";

export interface IComponent {
  hideLabel?: boolean;
  fieldsProps?: any;
  i18n: string;
  error?: string;
  value?: string;
  touched?: boolean;
  type?: string;
  disabled?: boolean;
  inputGroup?: any[];
  inputClassName?: string;
  optional?:boolean
  onClick?: () => void;
}

export default function (props: IComponent) {
  const intl = useIntl()

  return <>
    {!props.hideLabel &&
      <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({id: props.i18n})}</label>
    }

    <div className={'input-group'}>
      {props.inputGroup?.map((input) => (
        <span key={ulid()} className={'input-group-text border-0'}>{input}</span>
      ))}

      <input
        onClick={props.onClick}
        placeholder={`${intl.formatMessage({id: props.i18n})} ${props.optional ? (intl.formatMessage({id: "PLACEHOLDERS.OPTIONAL"})) : ''}`}
        type={props.type || 'text'}
        autoComplete='off'
        {...props.fieldsProps}
        disabled={props.disabled}
        value={props.value}
        className={props.inputClassName || 'form-control form-control-solid'}
      />
    </div>


    {(props.touched && props.error) && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{props.error}</span>
        </div>
      </div>
    )}
  </>
}
