import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ITalent from "../../interfaces/ITalent.ts";
import IAgencyTalent from "../../interfaces/IAgencyTalent.ts";
import IPageData from "../../interfaces/IPageData.ts";

type IStore = {
  agencyTalent?: IAgencyTalent
  talent?: ITalent
  completed?: string;
  save?: boolean
  pageData: {
    [key: string]: IPageData
  }
}

const initialState: IStore = {
  completed: '0',
  save: false,
  pageData: {}
};

const store = createSlice({
  name: EStores.TALENT,
  initialState,
  reducers: {
    setAgencyTalent(state: IStore, data: PayloadAction<IAgencyTalent>) {
      state.agencyTalent = data.payload;

      if (state.agencyTalent) {
        const values: boolean[] = [
          state.agencyTalent.talent.talent?.legal?.length !== 0,
          state.agencyTalent.talent.talent?.media?.files?.length !== 0,
          !!state.agencyTalent.talent.name,
          !!state.agencyTalent.talent.lastName,
          !!state.agencyTalent.talent.email,
        ]

        const cmp = values.filter((value) => value).length

        state.completed = (cmp / values.length * 100).toFixed(1)
      }

      return
    },
    clearTalent(state: IStore) {
      state.talent = undefined;
      state.agencyTalent = undefined;
      state.completed = '0';
      state.save = false;
      state.pageData = {};
      return
    },
    setAgencyTalentValues(state: IStore, data: PayloadAction<IPageData>) {
      state.pageData[data.payload.key] = data.payload;
      return
    },
    saveTalent(state: IStore) {
      state.save = true
      return
    },
    setSaveTalentFalse(state: IStore) {
      state.save = false;
      return
    },
    updateTalent(state:IStore, data: PayloadAction<ITalent>){
      if(state.agencyTalent){
        state.agencyTalent.talent = data.payload
      }
    }
  },
});

export const {
  setAgencyTalent,
  clearTalent,
  saveTalent,
  setSaveTalentFalse,
  setAgencyTalentValues,
  updateTalent
} = store.actions;

export default store.reducer;
