import {useEffect, useState} from "react";
import ITalent from "../core/interfaces/ITalent";
import getProfilePicture from "../core/utils/getProfilePicture";
import updateCompanyPackageTalentStatusRequest from "../core/api/requests/companies/package/updateCompanyPackageTalentStatusRequest";
import { useDispatch } from "react-redux";
import convertToFraction from "../core/utils/convertToFraction";
import { useIntl } from "react-intl";
import convertToAge from "../core/utils/convertToAge";
import moment from 'moment/moment'
import { allowSelection } from "@fullcalendar/core/internal";

export interface IComponent {
  talent: ITalent;
  companyId?:string
  packageId?:string
  selected?:boolean
  showDetails?: boolean
  allowSelect?: boolean
  onSelect?: () => void
}

export default function TalentCard(props: IComponent) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [ imageUri, setImageUri ] = useState<string>('')
  const [ statusSelection, setStatusSelection] = useState<string>('')

  useEffect(() => {
    const u = getProfilePicture(props.talent)
    setImageUri(u)
    setStatusSelection(props.talent.status || 'pending')
  }, [props.talent]);

  const redirectToTalent = () => {
    if(props.talent.id && !props.allowSelect){
        window.open(`/talents/${props.talent.id}`, '_blank')
    }
  }

  const changeStatus = async () => {
    if(props.companyId && props.packageId && props.talent.id){
      await updateCompanyPackageTalentStatusRequest(dispatch,props.companyId, props.packageId, props.talent.id, statusSelection);
    }
  }
  const [ TO, setTO ] = useState<any>(null)
  useEffect(() => {
    if (TO) {
      clearTimeout(TO)
    }

    setTO(setTimeout(() => {
      changeStatus()
    }, 500))
  }, [statusSelection]);

  const {measurements, characteristics} = props.talent?.talent?.physical || {}
  const { personal, contact } = props.talent?.talent || {}


  return <div className={'talent-card col'}>
    <div className={`row mb-8`}>
        <div className={'col-3'}>
            <div className={'talent-cover'} style={{backgroundImage: `url(${imageUri})`}} onClick={() => redirectToTalent()}></div>  
        </div>
        <div className={'talent-info col-9 d-flex flex-column'}>
            <div className="talent-card-header d-flex justify-content-between align-items-center pb-2">
                <div className="talent-heading d-flex align-items-center">
                    <span className="talent-name text-uppercase fw-bold fs-4" onClick={() => redirectToTalent()}>{props.talent.name} {props.talent.lastName}</span>
                    {props.talent?.talent?.socialMedia?.socialMedia && 
                        <a className="btn btn-link btn-color-gray-800 btn-active-color-primary ms-3 py-0" target="_blank" href={`https://www.instagram.com/${props.talent?.talent?.socialMedia?.socialMedia[0].userName}`}>
                            <span className="social-icon fa-brands fa-instagram "></span>
                        </a>
                    }
                </div>
                {props?.allowSelect && 
                    <>
                        {props.selected ? 
                            <button className="btn btn-primary btn-active-light border btn-sm" onClick={props?.onSelect}>
                                <i className="fa-solid fa-check"></i>
                                {intl.formatMessage({id: 'PLACEHOLDERS.SELECTED'})}
                            </button>  
                        :
                            <button className="btn btn-light-dark btn-active-primary border btn-sm" onClick={props?.onSelect}>
                                <i className="fa-solid fa-plus"></i>
                                {intl.formatMessage({id: 'PLACEHOLDERS.SELECT'})}
                            </button>  
                        }
                    </>
                }
            </div>
            <div className="talent-details flex-grow-1 bg-light p-5">
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className="detail-element mb-1">
                            <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.AGE' })}</span>
                            <span className="detail-value ms-2">
                                {personal?.birthDate 
                                ? `${convertToAge(personal.birthDate)} ${intl.formatMessage({ id: 'COMMON.YEARS' })} (${moment(personal.birthDate).format('MMM DD, YYYY')})` 
                                : ''}
                            </span>
                        </div> 
                        <div className="detail-element mb-1">
                            <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.PHONE' })}</span>
                            <span className="detail-value ms-2">{contact?.phone ?? ''}</span>
                        </div> 
                        <div className="detail-element mb-1">
                            <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.ADDRESS' })}</span>
                            <span className="detail-value ms-2">{contact?.address?.province ?? ''}, {contact?.address?.country ?? ''}</span>
                        </div> 
                        <div className="detail-element mb-1">
                            <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.ETHNICITIES' })}</span>
                            <span className="detail-value ms-2">{personal?.ethnicity ? personal.ethnicity.join(', ') : ''}</span>
                        </div> 
                        {characteristics?.dressSize &&
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.DRESS_SIZE' })}</span>
                                <span className="detail-value ms-2">{characteristics.dressSize} {characteristics?.dressSizeMeasurementUnit ?? ''}</span>
                            </div> 
                        }
                        {characteristics?.shoesSize &&
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.SHOES_SIZE' })}</span>
                                <span className="detail-value ms-2">{characteristics.shoesSize} {characteristics?.shoesSizeMeasurementUnit ?? ''}</span>
                            </div>
                        }
                    </div>
                    <div className="col-12 col-lg-5">
                        {measurements?.height && 
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.HEIGHT' })}</span>
                                <span className="detail-value ms-2">{`${measurements.height}cm / ${convertToFraction(measurements.height)}`}</span>
                            </div>          
                        }
                        {measurements?.bust && 
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.BUST' })}</span>
                                <span className="detail-value ms-2">{`${measurements.bust}cm / ${convertToFraction(measurements.bust)}`}</span>
                            </div>          
                        }
                        {measurements?.waist && 
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.WAIST' })}</span>
                                <span className="detail-value ms-2">{`${measurements.waist}cm / ${convertToFraction(measurements.waist)}`}</span>
                            </div>          
                        }
                        {measurements?.hip && 
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.HIP' })}</span>
                                <span className="detail-value ms-2">{`${measurements.hip}cm / ${convertToFraction(measurements.hip)}`}</span>
                            </div>          
                        }
                        {characteristics?.hairColor &&
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.HAIR_COLOR' })}</span>
                                <span className="detail-value ms-2">{characteristics.hairColor}</span>
                            </div>
                        }
                        {characteristics?.eyesColor &&
                            <div className="detail-element mb-1">
                                <span className="detail-title text-muted mt-1 fs-7">{intl.formatMessage({ id: 'PLACEHOLDERS.EYES_COLOR' })}</span>
                                <span className="detail-value ms-2 text-capitalize">{characteristics.eyesColor}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
}
